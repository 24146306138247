export const logo = [
  "608 134",
  `
  <title>coreui</title>
  <g transform="translate(0.000000,205.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2025 700 c-5 -16 -11 -19 -28 -13 -12 4 -46 8 -75 8 -48 0 -57 -4
-83 -33 -31 -34 -37 -73 -18 -115 9 -19 7 -30 -10 -58 -27 -44 -26 -70 3 -102
22 -24 80 -47 118 -47 24 0 29 -14 13 -36 -23 -30 -18 -39 27 -51 48 -13 60
-7 84 38 20 39 18 83 -6 104 -11 10 -49 27 -85 39 -72 23 -85 43 -25 38 50 -5
94 21 116 66 9 19 14 45 11 59 -3 15 3 40 15 60 22 37 18 48 -24 58 -21 5 -27
3 -33 -15z m-65 -84 c6 -8 10 -25 8 -38 -4 -32 -42 -32 -46 0 -7 46 14 66 38
38z"/>
<path d="M2565 704 c-5 -2 -28 -8 -50 -13 l-40 -10 3 -206 3 -205 59 0 60 0 0
66 c0 64 1 65 23 57 129 -46 246 108 179 237 -33 62 -119 88 -183 55 -27 -15
-30 -14 -37 3 -4 10 -11 18 -17 16z m126 -135 c10 -27 10 -36 -2 -58 -16 -29
-52 -39 -75 -21 -17 15 -19 81 -2 98 7 7 24 12 39 12 20 0 30 -7 40 -31z"/>
<path d="M210 683 c-17 -12 -25 -13 -28 -5 -2 7 -24 12 -53 12 l-49 0 0 -150
0 -150 60 0 60 0 0 93 c0 99 7 121 36 115 17 -3 19 -15 22 -106 l3 -102 59 0
60 0 0 88 c0 100 15 136 47 116 15 -9 19 -29 23 -102 3 -50 10 -96 16 -102 7
-7 31 -8 67 -4 54 6 55 7 46 31 -5 13 -9 60 -9 104 0 127 -33 179 -116 179
-27 0 -51 -7 -69 -21 -16 -13 -30 -18 -33 -12 -23 34 -103 43 -142 16z"/>
<path d="M1536 691 c-4 -6 -4 -27 0 -47 6 -33 9 -35 33 -31 15 3 33 2 41 -4
11 -6 7 -9 -15 -9 -52 0 -118 -40 -133 -81 -12 -31 -11 -41 2 -73 21 -51 71
-72 134 -56 33 8 47 8 52 0 5 -7 24 -9 58 -4 l52 7 -1 96 c-1 163 -29 202
-148 209 -43 3 -71 0 -75 -7z m104 -177 c0 -3 -5 -14 -10 -25 -18 -33 -59 -18
-46 16 5 14 56 22 56 9z"/>
<path d="M2190 678 c-51 -35 -73 -71 -78 -130 -7 -97 61 -161 174 -162 34 -1
65 1 68 4 2 3 2 24 -1 48 l-6 42 -48 0 c-26 0 -51 5 -54 10 -4 6 27 10 84 10
l91 0 0 48 c0 95 -56 152 -151 152 -31 0 -57 -7 -79 -22z m110 -73 c11 -13 7
-15 -30 -15 -37 0 -41 2 -30 15 7 8 20 15 30 15 10 0 23 -7 30 -15z"/>
<path d="M2946 691 c-4 -6 -4 -27 0 -47 6 -33 9 -35 33 -31 15 3 33 2 41 -4
11 -6 7 -9 -15 -9 -52 0 -118 -40 -133 -81 -12 -31 -11 -41 2 -73 21 -51 71
-72 134 -56 33 8 47 8 52 0 5 -7 24 -9 58 -4 l52 7 -1 96 c-1 163 -29 202
-148 209 -43 3 -71 0 -75 -7z m104 -177 c0 -3 -5 -14 -10 -25 -18 -33 -59 -18
-46 16 5 14 56 22 56 9z"/>
<path d="M613 678 c3 -7 31 -71 61 -141 31 -71 53 -133 50 -139 -4 -5 -22 -17
-41 -24 -32 -14 -34 -17 -28 -47 14 -68 15 -70 48 -63 55 11 101 50 135 113
31 59 122 285 122 303 0 6 -29 10 -64 10 l-65 0 -16 -75 c-9 -41 -19 -75 -21
-75 -2 0 -13 34 -25 75 l-22 75 -70 0 c-51 0 -68 -3 -64 -12z"/>
<path d="M976 666 c3 -14 23 -80 44 -148 l38 -123 59 -3 60 -3 15 68 15 68 10
-40 c6 -22 14 -52 17 -67 6 -27 9 -28 64 -28 l58 0 43 135 c23 74 44 142 47
150 5 12 -5 15 -54 15 l-61 0 -11 -57 c-17 -92 -26 -105 -34 -58 -4 22 -12 57
-17 78 -10 36 -11 37 -59 37 l-49 0 -17 -80 c-10 -44 -19 -78 -21 -77 -2 2 -8
30 -14 63 -17 99 -13 94 -80 94 -58 0 -59 -1 -53 -24z"/>
<path d="M3163 678 c3 -7 31 -71 61 -141 31 -71 53 -133 50 -139 -4 -5 -22
-17 -41 -24 -32 -14 -34 -17 -28 -47 14 -68 15 -70 48 -63 55 11 101 50 135
113 31 59 122 285 122 303 0 6 -29 10 -64 10 l-65 0 -16 -75 c-9 -41 -19 -75
-21 -75 -2 0 -13 34 -25 75 l-22 75 -70 0 c-51 0 -68 -3 -64 -12z"/>
</g>
`,
];
